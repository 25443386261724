<template>
    <AModalContent :show-close="true" class="create-bg-modal create-bg">
        <div class="create-bg-title">Create background</div>
        <form class="create-bg-form" method="#" @submit.prevent="onSubmit">
            <div class="create-bg-input">
                <label>Wallet</label>
                <span>{{ walletAddress }}</span>
            </div>
            <div class="create-bg-input">
                <label>Background Name</label>
                <input v-model="backgroundName" placeholder="Custom Background" class="app-input" name="name" />
            </div>
            <div class="create-bg-input">
                <label>Payment Token Address</label>
                <input v-model="paymentToken" class="app-input" name="payment_token" />
                <span v-if="paymentTokenName">Token Name: {{ paymentTokenName }}</span>
            </div>
            <div class="create-bg-input">
                <label>Supply</label>
                <input v-model="supply" class="app-input" name="amount" />
            </div>
            <div class="create-bg-input">
                <label>Price {{ paymentTokenName ? ` (${paymentTokenName})` : '' }}</label>
                <input v-model="price" class="app-input" name="price" />
            </div>
            <div class="create-bg-input">
                <label>Background Image</label>
                <input type="file" name="bg-image" accept="image/*" @change="onUploadFiles" />
            </div>

            <button class="create-bg-button" :class="{ disabled: isSending }" type="submit">
                <img v-if="isSending" class="loader" src="@/assets/img/spinner-clear.svg" width="20" /> Submit
            </button>
        </form>
    </AModalContent>
</template>
<script setup>
import { useWeb3Store } from "@/store/web3"

import AModalContent from "../Modal/AModalContent.vue"
import { computed, ref, watch } from "vue"
import { useApesStorageApi } from "@/api/useApesStorageApi"
import { web3 } from "@/web3"
import { useToast } from "@/composables/useToast"
import { getErc20TokenInfo } from "@/helpers/get-erc20-token-info"
const price = ref(1)
const supply = ref(1)
const paymentToken = ref('0x2170Ed0880ac9A755fd29B2688956BD959F933F8')
const backgroundName = ref('')
const isSending = ref(false)
const walletAddress = computed(() => useWeb3Store().walletAddress)
const paymentTokenName = ref('')
const backgroundImage = ref()
const emit = defineEmits(['close'])
const onUploadFiles = (e) => {
    const image = e.target.files[0]
    backgroundImage.value = image
}

const detectTokenName = async () => {
    if (!web3.utils.isAddress(paymentToken.value)) {
        paymentTokenName.value = ''
        return
    }
    const { symbol } = await getErc20TokenInfo(paymentToken.value)
    paymentTokenName.value = symbol
}
watch(paymentToken, detectTokenName)
detectTokenName()
const onSubmit = async () => {
    if (isSending.value) return
    const formData = new FormData();
    if (!web3.utils.isAddress(paymentToken.value)) {
        useToast().error({ title: "Please enter valid Payment Token Address" })
        return
    }
    if (!backgroundImage.value) {
        useToast().error({ title: "Please enter upload Image" })
        return
    }
    if (!price.value || price.value <= 0) {
        useToast().error({ title: "Please enter a valid Price" })
        return
    }
    if (!supply.value || supply.value < 1) {
        useToast().error({ title: "Please enter a valid Supply" })
        return
    }
    isSending.value = true
    const metadata = {
        wallet: walletAddress.value,
        name: backgroundName.value,
        creatorName: walletAddress.value,
        price: price.value,
        paymentToken: paymentToken.value,
        paymentTokenName: paymentTokenName.value,

        supply: supply.value
    };
    formData.append("image", backgroundImage.value, "image.png");
    formData.append("metadata", JSON.stringify(metadata));
    const done = await useApesStorageApi({ toast: { error: true } }).exec({
        url: '/configurator/save-background-image',
        method: "POST",
        data: formData
    })
    isSending.value = false
    if (!done) return
    useToast().success({ title: "Background was successfully uploaded!" })
    emit('close')
}
</script>
<style lang="scss">
.create-bg {
    width: 500px;
    max-width: 90%;
    z-index: 10;
    background-color: var(--gray-light);
    border-radius: 10px;
    padding: 20px;
    color: #fff;

    &-title {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 20px;
    }

    &-form {}

    &-input {
        text-align: left;
        margin-bottom: 15px;

        label {
            text-transform: uppercase;
            opacity: 0.5;
            font-weight: bold;
            font-size: 12px;
            margin-bottom: 5px;
            display: block;
        }

        input {
            background-color: transparent;
            padding: 5px 10px;
        }

        &-tooltip {
            margin-top: 4px;
            font-size: 14px;
            opacity: 0.8;

            b {
                font-weight: bold;
            }
        }
    }

    &-button {
        background-color: var(--primary);
        width: 100%;
        display: block;
        border-radius: 5px;
        border: none;
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        padding: 10px;
        cursor: pointer;
        margin-top: 20px;
        transition: 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;

        &.disabled {
            pointer-events: none;
            opacity: 0.5;
        }

        img {
            margin-right: 5px;
        }

        &:hover {
            filter: brightness(0.8);
        }
    }
}
</style>